
.home-header {
    padding: 0 25px;
    font-family: 'Times New Roman', Times, serif;
}

.hr {
    border: 1px solid rgb(2, 2, 2);
}

a:link {
    text-decoration: none;;
}

