.site-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
}

.footer {
    flex-shrink: 0;
}

@media only screen and (max-width: 600px) {
    .content,
    .site-wrapper {
        overflow: hidden;
    }
}