.email-gif {
    max-width: 100%;
}

.info-section {
    max-width: 1000px;
}

@media (max-width: 770px) {
    .shop-btn {
        width: 50vw !important;
    }
}